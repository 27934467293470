const BaseUrl = "/service/";
const BaseUrlTest = "/service/";
const ImageUrl = "/service/";
const Title = "Flight Transportation Management System";
const BgColor = "#a11645";
const backgroundGradient =
    "radial-gradient(circle, rgba(250,250,250,1) 0%, rgba(44,113,146,1) 30%)";
const NoVessel = false;
const internalDetail = true;
const colorPrimary = "#0B5351 ";
const colorSec = "#00A9A5";
const colorSecondary = "#4E8098";
const colorSecondary2 = "#90C2E7";
const colorSecondary3 = "#EEEFF2";
const colorSecondary4 = "#092327";
export default {
    BaseUrl,
    Title,
    BgColor,
    backgroundGradient,
    ImageUrl,
    NoVessel,
    internalDetail,
    colorPrimary,
    colorSecondary,
    colorSecondary3,
    colorSecondary2,
    colorSec,
    BaseUrlTest,
    colorSecondary4,
};
